// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-career-index-js": () => import("./../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-contactus-index-js": () => import("./../../src/pages/contactus/index.js" /* webpackChunkName: "component---src-pages-contactus-index-js" */),
  "component---src-pages-contactus-thankyou-js": () => import("./../../src/pages/contactus/thankyou.js" /* webpackChunkName: "component---src-pages-contactus-thankyou-js" */),
  "component---src-pages-core-engineer-js": () => import("./../../src/pages/core/engineer.js" /* webpackChunkName: "component---src-pages-core-engineer-js" */),
  "component---src-pages-core-index-js": () => import("./../../src/pages/core/index.js" /* webpackChunkName: "component---src-pages-core-index-js" */),
  "component---src-pages-core-port-js": () => import("./../../src/pages/core/port.js" /* webpackChunkName: "component---src-pages-core-port-js" */),
  "component---src-pages-corporateinfo-index-js": () => import("./../../src/pages/corporateinfo/index.js" /* webpackChunkName: "component---src-pages-corporateinfo-index-js" */),
  "component---src-pages-events-index-js": () => import("./../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-fleets-index-js": () => import("./../../src/pages/fleets/index.js" /* webpackChunkName: "component---src-pages-fleets-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-hsseq-index-js": () => import("./../../src/pages/hsseq/index.js" /* webpackChunkName: "component---src-pages-hsseq-index-js" */),
  "component---src-pages-hsseq-policy-js": () => import("./../../src/pages/hsseq/policy.js" /* webpackChunkName: "component---src-pages-hsseq-policy-js" */),
  "component---src-pages-investorcentre-analyst-js": () => import("./../../src/pages/investorcentre/analyst.js" /* webpackChunkName: "component---src-pages-investorcentre-analyst-js" */),
  "component---src-pages-investorcentre-annualreport-js": () => import("./../../src/pages/investorcentre/annualreport.js" /* webpackChunkName: "component---src-pages-investorcentre-annualreport-js" */),
  "component---src-pages-investorcentre-bursaanouncements-index-js": () => import("./../../src/pages/investorcentre/bursaanouncements/index.js" /* webpackChunkName: "component---src-pages-investorcentre-bursaanouncements-index-js" */),
  "component---src-pages-investorcentre-calendar-2014-js": () => import("./../../src/pages/investorcentre/calendar/2014.js" /* webpackChunkName: "component---src-pages-investorcentre-calendar-2014-js" */),
  "component---src-pages-investorcentre-calendar-2015-js": () => import("./../../src/pages/investorcentre/calendar/2015.js" /* webpackChunkName: "component---src-pages-investorcentre-calendar-2015-js" */),
  "component---src-pages-investorcentre-calendar-2016-js": () => import("./../../src/pages/investorcentre/calendar/2016.js" /* webpackChunkName: "component---src-pages-investorcentre-calendar-2016-js" */),
  "component---src-pages-investorcentre-calendar-2017-js": () => import("./../../src/pages/investorcentre/calendar/2017.js" /* webpackChunkName: "component---src-pages-investorcentre-calendar-2017-js" */),
  "component---src-pages-investorcentre-calendar-index-js": () => import("./../../src/pages/investorcentre/calendar/index.js" /* webpackChunkName: "component---src-pages-investorcentre-calendar-index-js" */),
  "component---src-pages-investorcentre-chairmanstatement-js": () => import("./../../src/pages/investorcentre/chairmanstatement.js" /* webpackChunkName: "component---src-pages-investorcentre-chairmanstatement-js" */),
  "component---src-pages-investorcentre-circular-js": () => import("./../../src/pages/investorcentre/circular.js" /* webpackChunkName: "component---src-pages-investorcentre-circular-js" */),
  "component---src-pages-investorcentre-corporategovernance-js": () => import("./../../src/pages/investorcentre/corporategovernance.js" /* webpackChunkName: "component---src-pages-investorcentre-corporategovernance-js" */),
  "component---src-pages-investorcentre-corporateinfo-corporatestructure-js": () => import("./../../src/pages/investorcentre/corporateinfo/corporatestructure.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-corporatestructure-js" */),
  "component---src-pages-investorcentre-corporateinfo-general-general-2014-js": () => import("./../../src/pages/investorcentre/corporateinfo/general/general-2014.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-general-general-2014-js" */),
  "component---src-pages-investorcentre-corporateinfo-general-general-2015-js": () => import("./../../src/pages/investorcentre/corporateinfo/general/general-2015.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-general-general-2015-js" */),
  "component---src-pages-investorcentre-corporateinfo-general-general-2016-js": () => import("./../../src/pages/investorcentre/corporateinfo/general/general-2016.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-general-general-2016-js" */),
  "component---src-pages-investorcentre-corporateinfo-general-general-2020-js": () => import("./../../src/pages/investorcentre/corporateinfo/general/general-2020.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-general-general-2020-js" */),
  "component---src-pages-investorcentre-corporateinfo-general-index-js": () => import("./../../src/pages/investorcentre/corporateinfo/general/index.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-general-index-js" */),
  "component---src-pages-investorcentre-corporateinfo-keymanagement-js": () => import("./../../src/pages/investorcentre/corporateinfo/keymanagement.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-keymanagement-js" */),
  "component---src-pages-investorcentre-corporateinfo-properties-index-js": () => import("./../../src/pages/investorcentre/corporateinfo/properties/index.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-properties-index-js" */),
  "component---src-pages-investorcentre-corporateinfo-properties-properties-2014-js": () => import("./../../src/pages/investorcentre/corporateinfo/properties/properties-2014.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-properties-properties-2014-js" */),
  "component---src-pages-investorcentre-corporateinfo-properties-properties-2015-js": () => import("./../../src/pages/investorcentre/corporateinfo/properties/properties-2015.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-properties-properties-2015-js" */),
  "component---src-pages-investorcentre-corporateinfo-properties-properties-2016-js": () => import("./../../src/pages/investorcentre/corporateinfo/properties/properties-2016.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-properties-properties-2016-js" */),
  "component---src-pages-investorcentre-corporateinfo-properties-properties-2017-js": () => import("./../../src/pages/investorcentre/corporateinfo/properties/properties-2017.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-properties-properties-2017-js" */),
  "component---src-pages-investorcentre-corporateinfo-subsidaries-index-js": () => import("./../../src/pages/investorcentre/corporateinfo/subsidaries/index.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-subsidaries-index-js" */),
  "component---src-pages-investorcentre-corporateinfo-subsidaries-subsidaries-2014-js": () => import("./../../src/pages/investorcentre/corporateinfo/subsidaries/subsidaries-2014.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-subsidaries-subsidaries-2014-js" */),
  "component---src-pages-investorcentre-corporateinfo-subsidaries-subsidaries-2015-js": () => import("./../../src/pages/investorcentre/corporateinfo/subsidaries/subsidaries-2015.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-subsidaries-subsidaries-2015-js" */),
  "component---src-pages-investorcentre-corporateinfo-subsidaries-subsidaries-2016-js": () => import("./../../src/pages/investorcentre/corporateinfo/subsidaries/subsidaries-2016.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-subsidaries-subsidaries-2016-js" */),
  "component---src-pages-investorcentre-corporateinfo-subsidaries-subsidaries-2019-js": () => import("./../../src/pages/investorcentre/corporateinfo/subsidaries/subsidaries-2019.js" /* webpackChunkName: "component---src-pages-investorcentre-corporateinfo-subsidaries-subsidaries-2019-js" */),
  "component---src-pages-investorcentre-corporatepresentations-index-js": () => import("./../../src/pages/investorcentre/corporatepresentations/index.js" /* webpackChunkName: "component---src-pages-investorcentre-corporatepresentations-index-js" */),
  "component---src-pages-investorcentre-index-js": () => import("./../../src/pages/investorcentre/index.js" /* webpackChunkName: "component---src-pages-investorcentre-index-js" */),
  "component---src-pages-investorcentre-mdna-js": () => import("./../../src/pages/investorcentre/mdna.js" /* webpackChunkName: "component---src-pages-investorcentre-mdna-js" */),
  "component---src-pages-investorcentre-policy-js": () => import("./../../src/pages/investorcentre/policy.js" /* webpackChunkName: "component---src-pages-investorcentre-policy-js" */),
  "component---src-pages-investorcentre-prospectus-js": () => import("./../../src/pages/investorcentre/prospectus.js" /* webpackChunkName: "component---src-pages-investorcentre-prospectus-js" */),
  "component---src-pages-investorcentre-quarter-js": () => import("./../../src/pages/investorcentre/quarter.js" /* webpackChunkName: "component---src-pages-investorcentre-quarter-js" */),
  "component---src-pages-investorcentre-terms-js": () => import("./../../src/pages/investorcentre/terms.js" /* webpackChunkName: "component---src-pages-investorcentre-terms-js" */),
  "component---src-pages-lounge-index-js": () => import("./../../src/pages/lounge/index.js" /* webpackChunkName: "component---src-pages-lounge-index-js" */),
  "component---src-pages-media-index-js": () => import("./../../src/pages/media/index.js" /* webpackChunkName: "component---src-pages-media-index-js" */),
  "component---src-pages-ourorganisation-index-js": () => import("./../../src/pages/ourorganisation/index.js" /* webpackChunkName: "component---src-pages-ourorganisation-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-videos-index-js": () => import("./../../src/pages/videos/index.js" /* webpackChunkName: "component---src-pages-videos-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-analyst-page-js": () => import("./../../src/templates/analyst-page.js" /* webpackChunkName: "component---src-templates-analyst-page-js" */),
  "component---src-templates-annualreport-page-js": () => import("./../../src/templates/annualreport-page.js" /* webpackChunkName: "component---src-templates-annualreport-page-js" */),
  "component---src-templates-article-page-js": () => import("./../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-bursa-page-js": () => import("./../../src/templates/bursa-page.js" /* webpackChunkName: "component---src-templates-bursa-page-js" */),
  "component---src-templates-calendar-page-js": () => import("./../../src/templates/calendar-page.js" /* webpackChunkName: "component---src-templates-calendar-page-js" */),
  "component---src-templates-circular-page-js": () => import("./../../src/templates/circular-page.js" /* webpackChunkName: "component---src-templates-circular-page-js" */),
  "component---src-templates-corporatehistory-page-js": () => import("./../../src/templates/corporatehistory-page.js" /* webpackChunkName: "component---src-templates-corporatehistory-page-js" */),
  "component---src-templates-director-page-js": () => import("./../../src/templates/director-page.js" /* webpackChunkName: "component---src-templates-director-page-js" */),
  "component---src-templates-event-page-js": () => import("./../../src/templates/event-page.js" /* webpackChunkName: "component---src-templates-event-page-js" */),
  "component---src-templates-fleet-page-js": () => import("./../../src/templates/fleet-page.js" /* webpackChunkName: "component---src-templates-fleet-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-hsseq-page-js": () => import("./../../src/templates/hsseq-page.js" /* webpackChunkName: "component---src-templates-hsseq-page-js" */),
  "component---src-templates-management-page-js": () => import("./../../src/templates/management-page.js" /* webpackChunkName: "component---src-templates-management-page-js" */),
  "component---src-templates-media-page-js": () => import("./../../src/templates/media-page.js" /* webpackChunkName: "component---src-templates-media-page-js" */),
  "component---src-templates-presentation-page-js": () => import("./../../src/templates/presentation-page.js" /* webpackChunkName: "component---src-templates-presentation-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-prospectus-page-js": () => import("./../../src/templates/prospectus-page.js" /* webpackChunkName: "component---src-templates-prospectus-page-js" */),
  "component---src-templates-quarter-page-js": () => import("./../../src/templates/quarter-page.js" /* webpackChunkName: "component---src-templates-quarter-page-js" */),
  "component---src-templates-tags-js": () => import("./../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

